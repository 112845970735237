<template>
  <div class="row col-md-12">
    <!-- Filter -->
    <div class="col-md-12">
      <validation-observer ref="filteruser" tag="form">
        <b-form>
          <div class="row">
            <!-- Filter Firstname -->
            <div class="col-md-4">
              <b-form-group label="Nama Depan">
                <validation-provider #default="{ errors }" name="firstname" rules="required">
                  <b-form-input v-model="filterFirstName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- Filter Lastname -->
            <div class="col-md-4">
              <b-form-group label="Nama Belakang">
                <validation-provider #default="{ errors }" name="lastname" rules="">
                  <b-form-input v-model="filterLastName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="-">
                <b-button type="button" variant="primary" @click="submitFilter()">Cari</b-button>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div class="col-md-12">
      <loading v-if="Loading" />
        <b-table v-if="!Loading && tableData.length>0" striped hover :items="tableData" :fields="tableFields" :per-page="perPage" :current-page="currentPage" show-empty>
          <template v-slot:cell(biodata)="row">
            <div class="d-flex" @click="editItem(row.item)">
              <img :src="require('@/assets/images/avatars/8.png')" v-if="row.item.imgurl == null && row.item.sex == 'm' || row.item.valid_img == false && row.item.sex == 'm'" style="min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px;">
              <img :src="require('@/assets/images/avatars/11.png')" v-if="row.item.imgurl == null && row.item.sex == 'f' || row.item.valid_img == false && row.item.sex == 'f'" style="min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px;">
              <img :src="row.item.imgurl" style="min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
              <!-- Informasi biodata pertama -->
              <div class="col-3">
                <p><strong>Nama Awal:</strong> <br/>{{ row.item.firstname }}</p>
                <p><strong>Nama Akhir:</strong><br/> {{ row.item.lastname }}</p>
                <p><strong>Jenis Kelamin:</strong> <br/>{{ row.item.sex }}</p>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Nama KTP:</strong> <br/><span style="color: yellow;">{{ row.item.fullname }}</span></p>
                <p><strong>Tanggal Lahir:</strong><br/> {{ row.item.dateofbirth }}</p>
                <!-- <p><strong>Pekerjaan:</strong><br/> {{ row.item.job }}</p> -->
              </div>
              <div class="col-6"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <div class="col-md-6">
                  <b-alert variant="success" show class="total-project-box" style="text-align: center;">
                    <p>Kepemilikan Lisensi</p>
                    <h3 style="color: white;">{{ row.item.total_license }}</h3>
                  </b-alert>
                </div>
                <div class="col-md-6">
                  <b-alert variant="warning" show class="total-project-box" style="text-align: center;">
                      <p>Total Crime</p>
                      <h3 style="color: white;">{{ row.item.total_kriminal }}</h3>
                  </b-alert>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end" v-if="!Loading && tableData.length>0">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
  },
  data() {
    return {
      Loading: false,
      filterFirstName: null,
      filterLastName: null,
      tableFields: [
        { key: 'biodata', label: "Biodata"},
        // { key: 'action', label: "Action"}
      ],
      filterText: null,
      tableData: [],
      perPage: 5, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      showCatatanModal: false,
      keterangan: null,
      bukti1: null,
      bukti2: null,
      displayname:null,
      userid:null,
    };
  },
  methods: {
    init: function() {
      this.getCacheAvailable();
    },
    getCacheAvailable(){
      const cacheKey = 'dataWarga';
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData) {
        this.tableData = JSON.parse(cachedData);
        return;
      }
    },
    editItem(e){
      this.$router.push({ path: "/idp-warga-detail/"+e.id });
    },
    editCatatan(item) {
      this.displayname = item.fullname;
      this.userid = item.id;
      this.showCatatanModal = true;
    },
    getData() {
      this.Loading = true;
      axios.post("idp/user/list", { first_name: null, last_name: null })
        .then((response) => {
          this.tableData = response.data.data;
          localStorage.setItem('dataWarga', JSON.stringify(this.tableData)); // Save data to cache
        })
        .catch((error) => {
          if (error.status === 4000) {
            localStorage.clear();
            this.$router.push({ path: "/login" });
            this.$toast({
              title: "Login Expired",
              message: "Sesi Login Habis",
            });
          }
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    submitFilter() {
      this.$refs.filteruser.validate().then((success) => {
        if (success) {
          this.Loading = true;
          axios.post("idp/user/list", {
            first_name: this.filterFirstName,
            last_name: this.filterLastName,
          })
          .then((response) => {
            this.tableData = response.data.data;
            localStorage.setItem('dataWarga', JSON.stringify(this.tableData)); // Update cache
          })
          .catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              this.$toast({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          })
          .finally(() => {
            this.Loading = false;
          });
        }
      });
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped>
.rounded-image {
  border-radius: 50%;
}
</style>